// src/pages/Zdjecia.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Modal, Image, Accordion, Spinner } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import DodajZdjecie from '../components/DodajZdjecie';

const Zdjecia = () => {
  const [zadania, setZadania] = useState([]);
  const [zdjeciaMap, setZdjeciaMap] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showGallery, setShowGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [galleryStartIndex, setGalleryStartIndex] = useState(0);
  const [activeKey, setActiveKey] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchZadania();
  }, []);

  const fetchZadania = async () => {
    try {
      const response = await axios.get('/data/zadania.json');
      setZadania(response.data);
    } catch (error) {
      console.error('Błąd podczas pobierania zadań:', error);
    }
  };

  const fetchZdjeciaForZadanie = useCallback(async (zadanieId) => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/zdjecia.php?zadanieId=${zadanieId}`);
      setZdjeciaMap(prev => ({ ...prev, [zadanieId]: response.data }));
    } catch (error) {
      console.error(`Błąd podczas pobierania zdjęć dla zadania ${zadanieId}:`, error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleDodajZdjecie = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // Odśwież tylko zdjęcia dla aktualnie otwartego zadania
    if (activeKey) {
      fetchZdjeciaForZadanie(activeKey);
    }
  };

  const handleImageClick = (zadanieId, zdjecieIndex) => {
    const zdjeciaZadania = zdjeciaMap[zadanieId] || [];
    const galleryImagesForZadanie = zdjeciaZadania.map(zdjecie => ({
      original: `/uploads/${zdjecie.filename}`,
      thumbnail: `/uploads/${zdjecie.thumbnail}`,
      description: `${zdjecie.title} - ${zdjecie.description}`
    }));

    setGalleryImages(galleryImagesForZadanie);
    setGalleryStartIndex(zdjecieIndex);
    setShowGallery(true);
  };

  const handleAccordionToggle = (zadanieId) => {
    if (activeKey === zadanieId) {
      setActiveKey(null);
    } else {
      setActiveKey(zadanieId);
      if (!zdjeciaMap[zadanieId]) {
        fetchZdjeciaForZadanie(zadanieId);
      }
    }
  };

  return (
    <Container fluid>
      <h1 className="my-4">Galeria zdjęć</h1>
      <Button variant="primary" onClick={handleDodajZdjecie} className="mb-4">
        Dodaj zdjęcie
      </Button>
      <Accordion activeKey={activeKey} onSelect={handleAccordionToggle}>
        {zadania.map(zadanie => (
          <Accordion.Item key={zadanie.id} eventKey={zadanie.id.toString()}>
            <Accordion.Header>
              Zadanie ID: {zadanie.id} - {zadanie.title} (Status: {zadanie.status})
            </Accordion.Header>
            <Accordion.Body>
              <p>Data rozpoczęcia: {zadanie.start_date}</p>
              <p>Data zakończenia: {zadanie.end_date}</p>
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Ładowanie...</span>
                  </Spinner>
                </div>
              ) : (
                <Row xs={1} sm={2} md={3} lg={4} className="g-4">
                  {(zdjeciaMap[zadanie.id] || []).map((zdjecie, index) => (
                    <Col key={zdjecie.id}>
                      <div
                        className="d-flex align-items-center justify-content-center bg-light rounded cursor-pointer"
                        onClick={() => handleImageClick(zadanie.id.toString(), index)}
                        style={{ aspectRatio: '1 / 1', overflow: 'hidden' }}
                      >
                        <Image
                          src={`/uploads/${zdjecie.thumbnail}`}
                          alt={zdjecie.title}
                          fluid
                          className="h-100 w-100 object-fit-contain"
                        />
                      </div>
                      <div className="mt-2">
                        <h5>{zdjecie.title}</h5>
                        <p className="text-muted">{zdjecie.description.substring(0, 50)}...</p>
                      </div>
                    </Col>
                  ))}
                </Row>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      {/* Modal do dodawania zdjęcia */}
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Dodaj nowe zdjęcie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DodajZdjecie onUploadSuccess={handleCloseModal} />
        </Modal.Body>
      </Modal>

      {/* ImageGallery */}
      {showGallery && (
        <Modal show={showGallery} onHide={() => setShowGallery(false)} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Galeria zdjęć</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ImageGallery
              items={galleryImages}
              startIndex={galleryStartIndex}
              showPlayButton={false}
              showFullscreenButton={true}
              showNav={true}
              useBrowserFullscreen={false}
            />
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
};

export default Zdjecia;
