// src/pages/Zadania.js
import React, { useState, useEffect } from 'react';
import { Container, Table, Spinner, Badge } from 'react-bootstrap';
import axios from 'axios';

const Zadania = () => {
  const [zadania, setZadania] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchZadania = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/data/zadania.json');
        setZadania(response.data);
        setError(null);
      } catch (err) {
        setError('Wystąpił błąd podczas ładowania zadań.');
      } finally {
        setLoading(false);
      }
    };

    fetchZadania();
  }, []);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Ładowanie...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return <Container className="mt-4"><div className="alert alert-danger">{error}</div></Container>;
  }

  return (
    <Container className="mt-4">
      <h1>Lista zadań</h1>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Tytuł</th>
            <th>Data rozpoczęcia</th>
            <th>Data zakończenia</th>
            <th>Liczba zdjęć</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {zadania.map((zadanie) => (
            <tr key={zadanie.id}>
              <td>{zadanie.id}</td>
              <td>{zadanie.title}</td>
              <td>{zadanie.start_date}</td>
              <td>{zadanie.end_date}</td>
              <td>{zadanie.photo_count}</td>
              <td>
                <Badge bg={zadanie.status === 'open' ? 'success' : 'secondary'}>
                  {zadanie.status === 'open' ? 'Otwarte' : 'Zamknięte'}
                </Badge>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Zadania;
