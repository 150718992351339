// src/components/DodajZdjecie.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, ProgressBar, Alert, Image } from 'react-bootstrap';

const DodajZdjecie = ({ onUploadSuccess }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [zadania, setZadania] = useState([]);
  const [selectedZadanieId, setSelectedZadanieId] = useState('');

  useEffect(() => {
    const fetchZadania = async () => {
      try {
        const response = await axios.get('/data/zadania.json');
        setZadania(response.data);
      } catch (error) {
        console.error('Błąd podczas pobierania zadań:', error);
      }
    };

    fetchZadania();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewUrl('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('file', file);
    formData.append('zadanieId', selectedZadanieId);

    try {
      await axios.post('/api/upload.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        }
      });
      setUploadStatus('success');
      setTimeout(() => {
        onUploadSuccess();
      }, 1500);
    } catch (error) {
      console.error('Error uploading file:', error);
      setUploadStatus('error');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Tytuł</Form.Label>
        <Form.Control
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Opis</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Zadanie</Form.Label>
        <Form.Select
          value={selectedZadanieId}
          onChange={(e) => setSelectedZadanieId(e.target.value)}
          required
        >
          <option value="">Wybierz zadanie</option>
          {zadania.map((zadanie) => (
            <option key={zadanie.id} value={zadanie.id}>
              {zadanie.title}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Zdjęcie</Form.Label>
        <Form.Control
          type="file"
          onChange={handleFileChange}
          required
        />
      </Form.Group>
      {previewUrl && (
        <div className="mb-3">
          <h5>Podgląd:</h5>
          <Image src={previewUrl} alt="Podgląd" thumbnail style={{ maxWidth: '200px' }} />
        </div>
      )}
      <Button variant="primary" type="submit">
        Prześlij
      </Button>
      {uploadProgress > 0 && (
        <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} className="mt-3" />
      )}
      {uploadStatus === 'success' && (
        <Alert variant="success" className="mt-3">
          Zdjęcie zostało pomyślnie przesłane!
        </Alert>
      )}
      {uploadStatus === 'error' && (
        <Alert variant="danger" className="mt-3">
          Wystąpił błąd podczas przesyłania zdjęcia.
        </Alert>
      )}
    </Form>
  );
};

export default DodajZdjecie;
