// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import Kontakt from './pages/Kontakt';
import Zdjecia from './pages/Zdjecia';
import Zadania from './pages/Zadania';

const App = () => {
  return (
    <Router>
      <Navigation />
      <Container>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/zdjecia" element={<Zdjecia />} />
          <Route path="/zadania" element={<Zadania />} />
        </Routes>
      </Container>
    </Router>
  );
};

export default App;
